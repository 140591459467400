/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const hotPink = css`
  color: hotpink;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 9vw;
  padding-right: 2vw;
`;

export const HeaderSection = styled.div`
  display: flex;
`;

export const HeaderLogo = styled.div`
`;

export const HeaderItem = styled.div`
  &:hover {
    color: purple;
  }
  cursor: pointer;
`;

export const banner = css`
  margin-top: -1vw;
  margin-bottom: 1vw;
  margin-left: 18vw;
  width: 65vw;
  -webkit-transform: rotate(1deg);
  -moz-transform: rotate(1deg);
  -o-transform: rotate(1deg);
  -ms-transform: rotate(1deg);
  transform: rotate(1deg);
`;

export const aboutArrow = css`
  height: 4vw;
  padding-right: 0.2vw;
`;

export const teamArrow = css`
  height: 6vw;
  padding-right: 0.2vw;
`;

export const linkArrow = css`
  height: 8vw;
`;