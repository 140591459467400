import React from 'react';
import { Routes, Route } from "react-router-dom";

// components
import { Home } from './components/common/Home';
import { Terms } from './components/common/Terms';

// styles
import * as AppComponents from './styles/App';
import './App.css';

function App() {
  return (
    <AppComponents.App>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ethterrestrial" element={<Home />} />
        <Route path="terms" element={<Terms />} />
      </Routes>
    </AppComponents.App>
  );
}

export default App;
