/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import MintButton from '../../assets/img/transact/mint_button.png';
import BeamUpButton from "../../assets/img/transact/beam_up.png"
import DecreaseQuantityButton from '../../assets/img/transact/decrease_quantity.png';
import IncreaseQuantityButton from '../../assets/img/transact/increase_quantity.png';

export const Content = styled.div`
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;

// Mint Container //
export const LeftPanel = styled.div`
  position: relative;
  margin-top: -14vw;
  width: 85%;
`;

export const mintUfo = css`
  z-index: 1;
  position: relative;
  left: 3%;
  top: -6%;
  width: 35%;
`;

export const mintContainer = css`
  position: absolute;
  left: 2%;
  top: 10%;
  width: 100%;
  opacity: 0.8;
`;

export const MintButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 32%;
  left: 2%;
`;

export const mintButton = css`
  background: url(${MintButton});
  background-size: 100%;
  background-repeat: no-repeat;
  height: 11vw;
  line-height: 12vw;
  border: none;
  width: 40%;
  font-size: 4.8vw;
  cursor: pointer;
`;

export const decreaseQuantityButton = css`
  background: url(${DecreaseQuantityButton});
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 2vw;
  height: 7.5vw;
  border: none;
  width: 10%;
  font-size: 3.8vw;
  position: relative;
  cursor: pointer;
  
`;

export const increaseQuantityButton = css`
  background: url(${IncreaseQuantityButton});
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 2vw;
  height: 7.5vw;
  border: none;
  width: 10%;
  font-size: 3.8vw;
  position: relative;
  cursor: pointer;
  line-height: 10px;
`;

export const quantityValue = css`
  font-size: 3vw;
  line-height: 7.5vw;
`;

export const MiddlePanel = styled.div`
  position: relative;
  width: 40%;
`;

export const MintInfoContainer = styled.div`
  margin-top: 1vw;
`;

export const mintInfoTxt = css`
  font-size: 4vw;
`;

export const txInfoTxt = css`
  font-size: 2vw;
  text-decoration: none;
  max-width: 40vw;
`;

export const txLinkPending = css`
  color: #ffc727;
`;

export const txLinkSuccess = css`
  color: #f405e0;
`;

export const txLinkError = css`
  color: red;
`;

export const ethLogo = css`
  color: black;
  width: 1.4vw;
  padding-right: 8px;
`;

export const skeleOne = css`
  position: absolute;
  margin-top: 2vw;
  margin-left: 14vw;
  width: 6vw;
`;

export const skeleTwo = css`
  position: absolute;
  margin-top: 18vw;
  margin-left: 54vw;
  width: 10vw;
`;

export const planetOne = css`
  position: absolute;
  margin-top: 30vw;
  margin-left: 34vw;
  width: 30vw;
`;

export const meteor = css`
  position: absolute;
  margin-top: 30vw;
  margin-left: 64vw;
  width: 10vw;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
`;

// End Mint Container //

// Beam Up //
export const RightPanel = styled.div`
  position: relative;
  width: 30%;
`;

export const beamUpContainer = css`
  background: url(${BeamUpButton});
  background-size: 98%;
  background-repeat: no-repeat;
  height: 38vw;
  line-height: 49vw;
  border: none;
  width: 100%;
  margin-top: -15%;
  font-size: 3vw;
  cursor: pointer;
`;

export const beamUpMessage = css`
  text-align: center;
  position: absolute;
  top: 72%;
  left: 18%;
  width: 60%;
  font-size: 1.2vw;
`;

export const learnMoreBeamUp = css`
  position: absolute;
  top: 32%;
  left: 39%;
  font-size: 1.4vw;
  color: white;
`;
// End Beam Up //

// About //
export const TxtBlob = styled.div`
  width: 95vw;
`;

export const ByRow = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  position: relative;
`;


export const aboutTxt = css`
  top: 23%;
  left: 16vw;
  width: 72vw;
  font-size: 1.6vw;
  text-align: left;
  position: absolute;
`;

export const TeamContainer = styled.div`
  position: absolute;
  top: 66%;
  left: 42%;
`;

export const Team = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.8vw;
`;

export const TeamItem = styled.div`
`;

export const teamImg = css`
  width: 14.8vw;
`;

export const teamTxt = css`
  font-size: 3vw;
  text-align: center;
`;

export const by = css`
  font-size: 5vw;
  text-align: right;
`;

// End About //

// Links //
export const links = css`
  margin-top: 2vw;
  gap: 5vw;
`;

export const looksRareLink = css`
`;

export const openSeaLink = css`
  color: #f862e4;
`;

export const discordLink = css`
  color: #fd6901;
`;

export const twitterLink = css`
  color: #00f0ff;
`;

export const githubLink = css`
`;

export const meteorTwo = css`
  position: absolute;
  margin-top: -20vw;
  margin-left: -60vw;
  width: 20vw;
`;

export const learnMore = css`
  color: white;
`;
// End Links //




export const divImg = css`
  width: 100%;
`;

export const tooltip = css`
  top: -2vw;
  cursor: pointer;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
`;

/* Tooltip text */
export const tooltiptext = css`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;

  &:hover {
    visibility: visible;
  }
`;


export const donwloadInput = css`
  height: 3.35vw;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 0.2vw;
  font-size: 2.05vw;
`;

export const downloadButton = css`
  width: 14.4vw;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5vw;
  background: url(${MintButton});
  background-size: 100%;
  background-repeat: no-repeat;
  line-height: 6.15vw;
  border: none;
  font-size: 2.5vw;
  cursor: pointer;
`;