/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react';
import { Link as RouterLink } from "react-router-dom";

// styles
import * as AppStyles from '../../styles/App';

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  


  return (
    <div css={[AppStyles.txt, AppStyles.terms]}>
      <RouterLink to="/ethterrestrial"><h1>X</h1></RouterLink>
      <div>
        <h1>EthTerrestrials Terms & Conditions</h1>
        <p>EthTerrestrials are artworks hosted and running on Ethereum. The EthTerrestrial's Ethereum contract is ERC-721 so each EthTerrestrial is a Non-Fungible Token (NFT). This website is only a tool which allows users to purchase EthTerrestrial NFTs by interacting with the Ethereum network. Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions generated by this website before approval or signing of said transactions. Furthermore, as the EthTerrestrial smart contract runs on the Ethereum network, there is no ability to undo or change any transaction.</p>
        <p>This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you (the user) are accepting sole responsibility for any actions and transactions involving EthTerrestrials.</p>
        <h2>1. Fees and Payment</h2>
        <p>A. We have no liability to you or any third party for any claims or damages that may arise through the use of this site or underlying Ethterrestrial contract. All transactions are conducted on the Ethereum network and cannot be reversed.</p>
        <p>B. By utilizing this website and the underlying Ethterrestrial contract, you acknowledge the costs and risk associated with submitting transactions to the Ethereum network and acknowledge full responsibility for any claims or damages resulting from transactions submitted to the Ethereum network. These costs include but are not limited to gas, gas fees, and additional payment sent as part of the transaction.</p>
        <p>C. Each transaction contains a gas limit which specifies how much gas the submitted transaction can use. This website makes its best attempt to estimate a reasonable gas limit for a transaction. By using this website, you are accepting sole responsibility for the gas limit estimation and any failures associated with the gas limit.</p>
        <h2>2. Disclaimers</h2>
        <p>A. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE IS AT YOUR SOLE RISK, AND THAT THE SITE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THE SITE, ANY ETHEREUM SMART CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SITE WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE DATA PROVIDED THROUGH THE SITE WILL BE ACCURATE, (III) THE SITE OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SITE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SITE WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.</p>
        <p>B. YOU ACCEPT THE INHERENT SECURITY RISKS OF INTERACTING WITH THIS WEBSITE AND THE ETHEREUM NETWORK</p>
        <p>C. WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THIS WEBSITE AND THE ETHEREUM NETWORK.</p>
        <h2>3. Limitation of Liability</h2>
        <p>A. YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
        <p>B. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN. WE WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT THESE LIMITATIONS.</p>
        <h2>4. Indemnification</h2>
        <p>You agree to hold harmless and indemnify EthTerrestrials and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost, and reasonable attorneys' fees arising out of or in any way related to (i) your breach of these Terms, (ii) your misuse of the Site, or (iii) your violation of applicable laws, rules or regulations in connection with your access to or use of the Site.</p>
        <h2>5. Changes to the Terms and Conditions</h2>
        <p>We may make changes to the Terms at our discretion.</p>
        <h2>6. Dispute Resolution; Arbitration</h2>
        <p>All disputes arising out of or in connection with these Terms, including without limitation your access or use of the Site, or to any products sold or distributed through the Site, will be referred to and finally resolved by arbitration under the rules of the American Arbitration Association. The case will be adjudicated by a single arbitrator and will be administered by the American Arbitration Association in accordance with its applicable rules. Each party will cover its own fees and costs associated with the arbitration proceedings. The place of arbitration will be New York, New York. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Notwithstanding the foregoing, we may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction.</p>
      </div>
    </div>
  );
}

export { Terms };
