/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link as SmoothLink } from "react-scroll";

import * as HeaderStyles from '../../styles/Header';

// misc assets
import Banner from "../../../assets/img/banner.png"
import Arrow from "../../../assets/img/arrow.png"

interface Props {};

function Header(props: Props)  {

    return (
      <HeaderStyles.Header>
        <HeaderStyles.HeaderSection>
          <HeaderStyles.HeaderLogo>
          <img src={Banner} alt="ufo to take you home" css={[HeaderStyles.banner]}/>
          </HeaderStyles.HeaderLogo>
        </HeaderStyles.HeaderSection>
        <HeaderStyles.HeaderSection>
        <HeaderStyles.HeaderItem>
          <SmoothLink smooth={true} to="about">
            <img src={Arrow} alt="about"  css={[HeaderStyles.teamArrow]}/>
          </SmoothLink>
        </HeaderStyles.HeaderItem>
        <HeaderStyles.HeaderItem>
          <SmoothLink smooth={true} to="links">
            <img src={Arrow} alt="go to links"  css={[HeaderStyles.linkArrow]}/>
          </SmoothLink>
        </HeaderStyles.HeaderItem>
      </HeaderStyles.HeaderSection>
      </HeaderStyles.Header>
    )
};

export { Header };