/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link as RouterLink } from "react-router-dom";

// styles
import * as FooterStyles from '../../styles/Footer';
import * as AppStyles from '../../styles/App';

interface Props {};

function Footer(props: Props)  {
    return (
      <FooterStyles.Footer css={AppStyles.txt}>
        <div css={FooterStyles.cco}>
        <div css={FooterStyles.ccoLink}><a rel="noreferrer" target="_blank" href="http://creativecommons.org/publicdomain/zero/1.0/">
          <img src="http://i.creativecommons.org/p/zero/1.0/88x31.png" style={{ borderStyle: "none" }} alt="CC0" />
        </a></div>
        <div>To the extent possible under law, Kye, has waived all copyright and related or neighboring rights to Ethterrestrials.&nbsp;<RouterLink to="/terms" css={[FooterStyles.link]}>Terms & Conditions</RouterLink></div>
        </div>
    </FooterStyles.Footer>
    )
};

export { Footer };