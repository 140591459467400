import { css } from '@emotion/react';
import styled from '@emotion/styled';
import backsplash from '../../assets/img/backsplash.png';

export const App = styled.div`
  height: 185vw;
  background-image: url(${backsplash});
  background-color: #664dff;
  background-size: 100%;
  background-repeat: no-repeat;

`;

export const Background = styled.img`
`;

export const header = css`
  font-size: 4vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
`;

export const txt = css`
  font-family: VT323;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

export const terms = css`
  padding: 30px;
  color: lightgrey;
`;