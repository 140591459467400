import React from 'react';

// components
import { Header } from './Header';
import { MainContainer } from './MainContainer';
import { Footer } from './Footer';


function Home() {
  return (
    <div>
      <Header />
      <MainContainer />
      <Footer />
    </div>
  );
}

export { Home };
