/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Footer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  background-color: #310b59;
  color: #d3d3d3;
  font-size: 18px;
  height: 50px;
  overflow: scroll;
`;

export const FooterSection = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
`;

export const FooterLogo = styled.div`
  font-size: 40px;
  color: hotpink;
`;

export const FooterItem = styled.div`
  margin-left: 20px;
  font-size: 30px;
  color: lightgrey;
  &:hover {
    color: purple;
  }
  cursor: pointer;
`;

export const cco = css`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: auto;
  height: 50px;
`;


export const ccoLink = css`
  padding-right: 10px;
`;

export const link = css`
  color: white;
`;

